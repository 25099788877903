<template lang="pug">
t-btn(:outlined="isCartEmpty"
      :outlinedDefault="empty"
      :color="color"
      hero
      v-sound.blockBtn
      :disabled="(emptyDisabled && isEmpty) || disabled"
      @click="$emit('click',$event)" @right="right"  @left="left")

  //- Custom Buttons
  template(v-slot:left-content)
    slot(name="left-content" :isEmpty="isEmpty" :disabled="emptyDisabled && isEmpty")
  //- Left cart
  template(v-slot:left)
    CartIcon(v-if="cartIcon && !$slots['left'] && !reverse" :count="quantity")
    slot(name="left")
  //- Left cart inner
  template(v-slot:left-inner v-if="$scopedSlots['left-inner'] || ( !isCartEmpty && cartIcon != undefined && cartIcon != 'left')")
    //-  && !$slots.default
    slot(name="left-inner"): CartIcon(:count="quantity")

  template(v-if="$slots.default")
    slot(:empty="empty")
    //- t-anim-numcss.total.ezcar.font-weight-medium.ml-1(v-if="total" :number="total")
  template(v-else)
      span(v-if="isEmpty && !noEmpty") {{emptyLabel}}
      span.mr-1(v-else v-text="label")
      t-anim-numcss.total.ezcar.font-weight-medium(:number="total || totalSum" :hide="isEmpty && !noEmpty")

  //- Custom Buttons
  template(v-slot:right-content)
    slot(name="right-content" :isEmpty="isEmpty" :disabled="emptyDisabled && isEmpty")

  template(v-slot:right)
    CartIcon(v-if="cartIcon && !$slots['right'] && reverse" :count="quantity")
    slot(name="right")

  template(v-slot:right-inner v-if="!isEmpty && $scopedSlots['menu']")
    slot(name="menu" :menu="bottom" :hide="hide")
    v-icon.mr-2 {{bottom?'mdi-chevron-down':'mdi-chevron-up'}}

//- v-btn(v-if="icon" x-large depressed color="primary" @click="$emit('click')")
  v-badge(color="red" overlap)
    template(v-slot:badge) {{quantity}}
    v-avatar.white--text(color="primary" rounded size="32"): v-icon mdi-basket

//- v-row.flex-nowrap(no-gutters)
  .flex-grow-1.mr-2
    v-btn(color="primary" x-large block depressed)
      .d-flex.flex-column
        div Добавить в чек
        div.font-weight-regular  Итого
          t-anim-numcss(:number="2000")
  div
    Basket(empty="Go to cart" icon @click="showCart = true")

//- v-row(no-wrap dense)
  DialogCartActions(v-model="bottom")
  v-col(cols="10" v-if="$slots.default")
    v-btn(color="primary" x-large block depressed @click="$emit('left')")
      slot
  v-col(Zclass="{'offset-9':$slots.default}")
    v-btn(
      Zclass="{'pl-3 pr-1 justify-space-between':!$slots.default}"
      block
      x-large
      depressed
      :outlined="isEmpty"
      :disabled="totalSum < 1"
      color="primary"
      @click="$emit('click')")
      //- v-avatar.primary--text(color="white" tile rounded size="28") {{cart.length}}
      v-btn(v-if="!$slots.default && icon" icon color="white" @click.stop="bottom=true"): v-icon(v-if="!isEmpty") mdi-menu-up

      div(v-if="!$slots.default")
        template(v-if="isEmpty") Go to Cart
        template(v-else)
          | ОПЛАТА
          t-anim-numcss.total.ezcar.font-weight-medium.ml-2(:number="totalSum")
        //- span.total.ezcar.font-weight-medium.ml-2 {{ totalSum | numeral('0,0.[00]') }} ₸
      div
        CartIcon(:icon="icon" :count="cart.length")

</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import CartIcon from './_parts/CartIcon.vue'

export default {
  components: {
    CartIcon,
  },
  props: {
    noIcon: {
      type: Boolean,
      default: false,
    },
    emptyDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cartIcon: {
      type: String,
      default: undefined,
    },
    noActions: {
      type: Boolean,
      default: undefined,
    },
    noMenu: {
      type: Boolean,
      default: undefined,
    },
    reverse: {
      type: Boolean,
      default: undefined,
    },
    empty: {
      type: Boolean,
      default: undefined,
    },
    label: {
      type: String,
      default: 'К ОПЛАТЕ:',
    },
    noEmpty: {
      type: Boolean,
      default: undefined,
    },
    emptyLabel: {
      type: String,
      default: 'Go to cart',
    },
    color: {
      type: String,
      default: 'primary',
    },
    total: {
      type: Number,
      default: undefined,
    },
    menu: {
      type: Boolean,
      default: undefined,
    },
  },

  data: () => ({
    bottom: false,
  }),

  computed: {
    ...mapGetters({
      totalSum: 'tjtheme/total',
      cart: 'tjtheme/cart',
      quantity: 'tjtheme/quantity',
    }),
    isCartEmpty() {
      return !this.totalSum
    },
    isEmpty() {
      return !((this.total) || (this.totalSum && !this.total))
    },
  },

  watch: {
    menu(v) {
      // console.log('!-------------- showong menu', v)
      this.bottom = v
    },
  },

  methods: {
    ...mapActions({
      checkout: 'tjtheme/checkout',
    }),
    hasSecondButton() {
      return !!this.$slots.default
    },
    left() {
      this.$emit('left')
      if (!this.reverse) this.$emit('cart')
    },
    hide() {
      this.bottom = false
      this.$emit('menuHide')
    },
    right() {
      if (!this.noMenu && !this.noActions) this.bottom = true
      this.$emit('right')
      if (this.reverse) this.$emit('cart')
    },
  },
}
</script>
<style lang="stylus">
.total
  font-size 22px !important

.v-btn:not(.v-btn--round).v-size--x-large
  min-width: 60px
  padding 0
</style>
