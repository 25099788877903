<template lang="pug">
v-badge.t-badge(color="red" v-if="icon !== false" overlap Zbottom :value="count")
  template(v-slot:badge)
    transition(name="tbadge" mode="out-in")
      div.Zprimary--text(:key="count") {{count}}
  v-icon(Zcolor="white") mdi-basket
  //- v-avatar.white--text(color="primary" rounded size="28")
    //- v-icon mdi-cart
    v-icon mdi-basket
v-badge(v-else)
</template>
<script>

export default {
  props: {
    icon: {
      type: Boolean,
      default: undefined,
    },
    count: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
<style lang="stylus">
.tbadge-enter-active, .tbadge-leave-active
  transition: all 0.2s

.tbadge-enter
  opacity 1
  transform: translateY(5px);

.tbadge-leave-to
  opacity 0
  transform: translateY(-10px);

.t-badge

  .v-badge__badge
    overflow hidden

</style>
